<div [ngClass]="classes" *cftI18n="let t">
  <div
    class="form-field"
    [class.with-label]="!!label?.children?.length"
    [class.with-prefix]="prefix"
    [class.label-float-above]="hasValue || isTimeInput || isDateInput || isCftSelect"
    [class.auto-grow]="autogrow"
    [class.invalid]="invalid"
    [class.focus]="hasFocus"
    [class.has-warning]="hasWarning"
    [class.has-success]="hasSuccess"
  >
    <div class="input-container">
      @if (prefix) {
        <div class="prefix">{{ prefix }}</div>
      }
      <ng-content select="input, textarea, select, cft-select"></ng-content>
      <div class="notched-outline">
        <div class="notched-outline-leading"></div>
        <div class="notched-outline-notch">
          <div class="notched-outline-label">
            <span #label><ng-content select="label"></ng-content></span>
            @if (label?.children?.length && !required) {
              <span class="text-president-200">
                {{ t('optionalLabel') }}
              </span>
            }
          </div>
        </div>
        <div class="notched-outline-trailing pr-sm text-right">
          @if (limit) {
            <span
              class="relative top-[-0.9rem] rounded-3 border border-green-400 bg-white px-2xs text-xs"
              [class.border-red-400]="value?.length > limit"
              [class.bg-red-50]="value?.length > limit"
            >
              <span class="font-bold">{{ value?.length ?? 0 }}</span
              >/{{ limit }}
            </span>
          }
        </div>
      </div>
      @if (invalid && !isDateInput && !isTimeInput && !isCftSelect) {
        <fa-icon [icon]="errorIcon" class="error-icon"></fa-icon>
      }
      @if (hasWarning && !isDateInput && !isTimeInput && !isCftSelect) {
        <fa-icon [icon]="warningIcon" class="warning-icon"></fa-icon>
      }
      @if (hasSuccess) {
        <fa-icon [icon]="successIcon" class="success-icon"></fa-icon>
      }
    </div>
    @if (!invalid) {
      <span>
        <ng-content select=".help-text"></ng-content>
      </span>
    }
    @if (hasSuccess) {
      <span>
        <ng-content select=".success-text"></ng-content>
      </span>
    }
    @if (hasWarning) {
      <span>
        <ng-content select=".warn-text"></ng-content>
      </span>
    }
    <span #customErrorContainer class="block" [class.invisible]="valid" [class.h-0]="valid">
      <ng-content select=".error"></ng-content>
    </span>
    @if (!hasCustomErrors && invalid) {
      <span class="error">
        {{ t('fieldValidationMessage', {fieldName: label?.innerText || ''}) }}
      </span>
    }
  </div>
</div>
