import {Directive, ElementRef, Output, EventEmitter, AfterViewInit} from '@angular/core'
import {AbstractControl, FormControlDirective, NgControl} from '@angular/forms'

@Directive({
  selector:
    'input[formControlName], input[formControl], ' +
    'textarea[formControl], textarea[formControlName], ' +
    'select[formControl], select[formControlName], ' +
    'cft-select',
})
export class InputFormControlDirective implements AfterViewInit {
  constructor(
    private element: ElementRef,
    private control: NgControl,
  ) {}

  @Output() focusChanged = new EventEmitter<boolean>()

  ngAfterViewInit(): void {
    const {nativeElement} = this.element
    if (nativeElement) {
      nativeElement.onfocus = () => this.focusChanged.emit(true)
      nativeElement.onblur = () => this.focusChanged.emit(false)
    }
  }

  get hasValue(): boolean {
    const {nativeElement} = this.element

    if (['INPUT', 'TEXTAREA', 'SELECT'].includes(nativeElement?.tagName)) {
      return !!nativeElement?.value
    }

    const input = nativeElement?.querySelector('input')
    return !!input?.value
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get value(): any {
    return this.control.value
  }

  get isTimeInput(): boolean {
    const {nativeElement} = this.element
    return nativeElement?.type === 'time'
  }

  get isDateInput(): boolean {
    const {nativeElement} = this.element
    return nativeElement?.type === 'date' || nativeElement?.type === 'datetime-local'
  }

  get isCftSelect(): boolean {
    const {nativeElement} = this.element
    return nativeElement?.tagName === 'CFT-SELECT'
  }

  get invalid(): boolean | null {
    return this.control?.invalid && this.control?.touched
  }

  get tagName(): string {
    return this.element.nativeElement.tagName
  }

  get required(): boolean {
    const {control} = this.control as FormControlDirective

    return control?.validator ? control.validator({} as AbstractControl)?.required : false
  }
}
