import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core'
import {InputFormControlDirective} from './input-form-control.directive'
import {faExclamationCircle} from '@fortawesome/pro-solid-svg-icons/faExclamationCircle'
import {faTimesCircle} from '@fortawesome/pro-solid-svg-icons/faTimesCircle'
import {faCheckCircle} from '@fortawesome/pro-solid-svg-icons/faCheckCircle'
import {Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'

@Component({
  selector: 'cft-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.css'],
})
export class FormFieldComponent implements AfterContentInit, AfterViewInit, OnDestroy {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @HostBinding('class') @Input('class') classes = ''

  // height: 100% is currently (and has been for 10+ years) bugged in many browsers (chromium based) when used in conjunction with min-height
  // https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height
  // if that's a problem, we should fix using flex
  // https://stackoverflow.com/questions/8468066/child-inside-parent-with-min-height-100-not-inheriting-height#comment64966016_8468066
  @Input() autogrow = false
  @Input() prefix?: string

  @Input() hasWarning = false
  @Input() hasSuccess = false

  @Input() limit?: number

  @ContentChild(InputFormControlDirective) inputElement!: InputFormControlDirective
  @ViewChild('customErrorContainer') customErrorContainer?: ElementRef

  readonly errorIcon = faTimesCircle
  readonly warningIcon = faExclamationCircle
  readonly successIcon = faCheckCircle

  hasCustomErrors = false
  hasFocus = false

  private readonly unsubscribe$ = new Subject<void>()

  constructor(private readonly cdr: ChangeDetectorRef) {}

  get invalid(): boolean {
    return !!this.inputElement?.invalid
  }

  get valid(): boolean {
    return !this.invalid
  }

  get hasValue(): boolean {
    return this.inputElement?.hasValue
  }

  get value() {
    return this.inputElement.value
  }

  get required(): boolean {
    return this.inputElement?.required
  }

  get isCftSelect(): boolean {
    return this.inputElement?.isCftSelect
  }

  get isDateInput(): boolean {
    return this.inputElement?.isDateInput
  }

  get isTimeInput(): boolean {
    return this.inputElement?.isTimeInput
  }

  ngAfterContentInit(): void {
    if (!this.inputElement) {
      throw Error('Could not find input element. This component only supports selectors as defined in InputDirective')
    }
  }

  ngAfterViewInit(): void {
    const customError = this.customErrorContainer?.nativeElement.children[0]
    this.hasCustomErrors = customError?.classList.contains('error') ?? false
    this.inputElement.focusChanged.pipe(takeUntil(this.unsubscribe$)).subscribe(focus => {
      this.hasFocus = focus
      this.cdr.detectChanges()
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }
}
